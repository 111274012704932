// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-components-action-bar-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/action-bar/code.js" /* webpackChunkName: "component---src-pages-components-action-bar-code-js" */),
  "component---src-pages-components-action-bar-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/action-bar/design.js" /* webpackChunkName: "component---src-pages-components-action-bar-design-js" */),
  "component---src-pages-components-action-list-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/action-list/code.js" /* webpackChunkName: "component---src-pages-components-action-list-code-js" */),
  "component---src-pages-components-action-list-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/action-list/design.js" /* webpackChunkName: "component---src-pages-components-action-list-design-js" */),
  "component---src-pages-components-alert-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/alert/code.js" /* webpackChunkName: "component---src-pages-components-alert-code-js" */),
  "component---src-pages-components-alert-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/alert/design.js" /* webpackChunkName: "component---src-pages-components-alert-design-js" */),
  "component---src-pages-components-avatars-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/code.js" /* webpackChunkName: "component---src-pages-components-avatars-code-js" */),
  "component---src-pages-components-avatars-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/design.js" /* webpackChunkName: "component---src-pages-components-avatars-design-js" */),
  "component---src-pages-components-avatars-group-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/group/code.js" /* webpackChunkName: "component---src-pages-components-avatars-group-code-js" */),
  "component---src-pages-components-avatars-group-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/group/design.js" /* webpackChunkName: "component---src-pages-components-avatars-group-design-js" */),
  "component---src-pages-components-avatars-org-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/org/code.js" /* webpackChunkName: "component---src-pages-components-avatars-org-code-js" */),
  "component---src-pages-components-avatars-org-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/org/design.js" /* webpackChunkName: "component---src-pages-components-avatars-org-design-js" */),
  "component---src-pages-components-avatars-team-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/team/code.js" /* webpackChunkName: "component---src-pages-components-avatars-team-code-js" */),
  "component---src-pages-components-avatars-team-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/team/design.js" /* webpackChunkName: "component---src-pages-components-avatars-team-design-js" */),
  "component---src-pages-components-avatars-user-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/user/code.js" /* webpackChunkName: "component---src-pages-components-avatars-user-code-js" */),
  "component---src-pages-components-avatars-user-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/avatars/user/design.js" /* webpackChunkName: "component---src-pages-components-avatars-user-design-js" */),
  "component---src-pages-components-buttons-button-row-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/button-row/code.js" /* webpackChunkName: "component---src-pages-components-buttons-button-row-code-js" */),
  "component---src-pages-components-buttons-button-row-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/button-row/design.js" /* webpackChunkName: "component---src-pages-components-buttons-button-row-design-js" */),
  "component---src-pages-components-buttons-button-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/button/code.js" /* webpackChunkName: "component---src-pages-components-buttons-button-code-js" */),
  "component---src-pages-components-buttons-button-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/button/design.js" /* webpackChunkName: "component---src-pages-components-buttons-button-design-js" */),
  "component---src-pages-components-buttons-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/code.js" /* webpackChunkName: "component---src-pages-components-buttons-code-js" */),
  "component---src-pages-components-buttons-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/design.js" /* webpackChunkName: "component---src-pages-components-buttons-design-js" */),
  "component---src-pages-components-buttons-floating-action-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/floating-action/code.js" /* webpackChunkName: "component---src-pages-components-buttons-floating-action-code-js" */),
  "component---src-pages-components-buttons-floating-action-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/buttons/floating-action/design.js" /* webpackChunkName: "component---src-pages-components-buttons-floating-action-design-js" */),
  "component---src-pages-components-card-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/card/code.js" /* webpackChunkName: "component---src-pages-components-card-code-js" */),
  "component---src-pages-components-card-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/card/design.js" /* webpackChunkName: "component---src-pages-components-card-design-js" */),
  "component---src-pages-components-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/code.js" /* webpackChunkName: "component---src-pages-components-code-js" */),
  "component---src-pages-components-data-table-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/data-table/code.js" /* webpackChunkName: "component---src-pages-components-data-table-code-js" */),
  "component---src-pages-components-data-table-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/data-table/design.js" /* webpackChunkName: "component---src-pages-components-data-table-design-js" */),
  "component---src-pages-components-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/design.js" /* webpackChunkName: "component---src-pages-components-design-js" */),
  "component---src-pages-components-forms-checkbox-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/checkbox/code.js" /* webpackChunkName: "component---src-pages-components-forms-checkbox-code-js" */),
  "component---src-pages-components-forms-checkbox-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/checkbox/design.js" /* webpackChunkName: "component---src-pages-components-forms-checkbox-design-js" */),
  "component---src-pages-components-forms-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/code.js" /* webpackChunkName: "component---src-pages-components-forms-code-js" */),
  "component---src-pages-components-forms-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/design.js" /* webpackChunkName: "component---src-pages-components-forms-design-js" */),
  "component---src-pages-components-forms-form-modifier-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/form-modifier/code.js" /* webpackChunkName: "component---src-pages-components-forms-form-modifier-code-js" */),
  "component---src-pages-components-forms-form-modifier-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/form-modifier/design.js" /* webpackChunkName: "component---src-pages-components-forms-form-modifier-design-js" */),
  "component---src-pages-components-forms-form-section-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/form-section/code.js" /* webpackChunkName: "component---src-pages-components-forms-form-section-code-js" */),
  "component---src-pages-components-forms-form-section-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/form-section/design.js" /* webpackChunkName: "component---src-pages-components-forms-form-section-design-js" */),
  "component---src-pages-components-forms-input-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/input/code.js" /* webpackChunkName: "component---src-pages-components-forms-input-code-js" */),
  "component---src-pages-components-forms-input-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/input/design.js" /* webpackChunkName: "component---src-pages-components-forms-input-design-js" */),
  "component---src-pages-components-forms-radio-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/radio/code.js" /* webpackChunkName: "component---src-pages-components-forms-radio-code-js" */),
  "component---src-pages-components-forms-radio-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/radio/design.js" /* webpackChunkName: "component---src-pages-components-forms-radio-design-js" */),
  "component---src-pages-components-forms-search-input-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/search-input/code.js" /* webpackChunkName: "component---src-pages-components-forms-search-input-code-js" */),
  "component---src-pages-components-forms-search-input-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/search-input/design.js" /* webpackChunkName: "component---src-pages-components-forms-search-input-design-js" */),
  "component---src-pages-components-forms-text-area-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/text-area/code.js" /* webpackChunkName: "component---src-pages-components-forms-text-area-code-js" */),
  "component---src-pages-components-forms-text-area-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/forms/text-area/design.js" /* webpackChunkName: "component---src-pages-components-forms-text-area-design-js" */),
  "component---src-pages-components-icon-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/icon/code.js" /* webpackChunkName: "component---src-pages-components-icon-code-js" */),
  "component---src-pages-components-icon-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/icon/design.js" /* webpackChunkName: "component---src-pages-components-icon-design-js" */),
  "component---src-pages-components-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/index.js" /* webpackChunkName: "component---src-pages-components-index-js" */),
  "component---src-pages-components-keyboard-shortcut-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/keyboard-shortcut/code.js" /* webpackChunkName: "component---src-pages-components-keyboard-shortcut-code-js" */),
  "component---src-pages-components-keyboard-shortcut-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/keyboard-shortcut/design.js" /* webpackChunkName: "component---src-pages-components-keyboard-shortcut-design-js" */),
  "component---src-pages-components-legend-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/legend/code.js" /* webpackChunkName: "component---src-pages-components-legend-code-js" */),
  "component---src-pages-components-legend-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/legend/design.js" /* webpackChunkName: "component---src-pages-components-legend-design-js" */),
  "component---src-pages-components-link-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/link/code.js" /* webpackChunkName: "component---src-pages-components-link-code-js" */),
  "component---src-pages-components-link-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/link/design.js" /* webpackChunkName: "component---src-pages-components-link-design-js" */),
  "component---src-pages-components-modal-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/modal/code.js" /* webpackChunkName: "component---src-pages-components-modal-code-js" */),
  "component---src-pages-components-modal-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/modal/design.js" /* webpackChunkName: "component---src-pages-components-modal-design-js" */),
  "component---src-pages-components-notifications-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/code.js" /* webpackChunkName: "component---src-pages-components-notifications-code-js" */),
  "component---src-pages-components-notifications-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/design.js" /* webpackChunkName: "component---src-pages-components-notifications-design-js" */),
  "component---src-pages-components-notifications-note-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/note/code.js" /* webpackChunkName: "component---src-pages-components-notifications-note-code-js" */),
  "component---src-pages-components-notifications-note-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/note/design.js" /* webpackChunkName: "component---src-pages-components-notifications-note-design-js" */),
  "component---src-pages-components-notifications-notice-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/notice/code.js" /* webpackChunkName: "component---src-pages-components-notifications-notice-code-js" */),
  "component---src-pages-components-notifications-notice-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/notice/design.js" /* webpackChunkName: "component---src-pages-components-notifications-notice-design-js" */),
  "component---src-pages-components-notifications-toast-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/toast/code.js" /* webpackChunkName: "component---src-pages-components-notifications-toast-code-js" */),
  "component---src-pages-components-notifications-toast-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/notifications/toast/design.js" /* webpackChunkName: "component---src-pages-components-notifications-toast-design-js" */),
  "component---src-pages-components-overlay-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/overlay/code.js" /* webpackChunkName: "component---src-pages-components-overlay-code-js" */),
  "component---src-pages-components-overlay-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/overlay/design.js" /* webpackChunkName: "component---src-pages-components-overlay-design-js" */),
  "component---src-pages-components-select-mark-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/select-mark/code.js" /* webpackChunkName: "component---src-pages-components-select-mark-code-js" */),
  "component---src-pages-components-select-mark-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/select-mark/design.js" /* webpackChunkName: "component---src-pages-components-select-mark-design-js" */),
  "component---src-pages-components-selects-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/code.js" /* webpackChunkName: "component---src-pages-components-selects-code-js" */),
  "component---src-pages-components-selects-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/design.js" /* webpackChunkName: "component---src-pages-components-selects-design-js" */),
  "component---src-pages-components-selects-lookup-multi-select-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/lookup-multi-select/code.js" /* webpackChunkName: "component---src-pages-components-selects-lookup-multi-select-code-js" */),
  "component---src-pages-components-selects-lookup-multi-select-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/lookup-multi-select/design.js" /* webpackChunkName: "component---src-pages-components-selects-lookup-multi-select-design-js" */),
  "component---src-pages-components-selects-lookup-select-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/lookup-select/code.js" /* webpackChunkName: "component---src-pages-components-selects-lookup-select-code-js" */),
  "component---src-pages-components-selects-lookup-select-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/lookup-select/design.js" /* webpackChunkName: "component---src-pages-components-selects-lookup-select-design-js" */),
  "component---src-pages-components-selects-single-select-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/single-select/code.js" /* webpackChunkName: "component---src-pages-components-selects-single-select-code-js" */),
  "component---src-pages-components-selects-single-select-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/selects/single-select/design.js" /* webpackChunkName: "component---src-pages-components-selects-single-select-design-js" */),
  "component---src-pages-components-spinner-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/spinner/code.js" /* webpackChunkName: "component---src-pages-components-spinner-code-js" */),
  "component---src-pages-components-spinner-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/spinner/design.js" /* webpackChunkName: "component---src-pages-components-spinner-design-js" */),
  "component---src-pages-components-themes-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/themes/code.js" /* webpackChunkName: "component---src-pages-components-themes-code-js" */),
  "component---src-pages-components-themes-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/themes/design.js" /* webpackChunkName: "component---src-pages-components-themes-design-js" */),
  "component---src-pages-components-themes-environment-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/themes/environment/code.js" /* webpackChunkName: "component---src-pages-components-themes-environment-code-js" */),
  "component---src-pages-components-themes-environment-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/themes/environment/design.js" /* webpackChunkName: "component---src-pages-components-themes-environment-design-js" */),
  "component---src-pages-components-themes-level-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/themes/level/code.js" /* webpackChunkName: "component---src-pages-components-themes-level-code-js" */),
  "component---src-pages-components-themes-level-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/themes/level/design.js" /* webpackChunkName: "component---src-pages-components-themes-level-design-js" */),
  "component---src-pages-components-toast-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/toast/code.js" /* webpackChunkName: "component---src-pages-components-toast-code-js" */),
  "component---src-pages-components-toast-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/toast/design.js" /* webpackChunkName: "component---src-pages-components-toast-design-js" */),
  "component---src-pages-components-tooltip-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/tooltip/code.js" /* webpackChunkName: "component---src-pages-components-tooltip-code-js" */),
  "component---src-pages-components-tooltip-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/tooltip/design.js" /* webpackChunkName: "component---src-pages-components-tooltip-design-js" */),
  "component---src-pages-components-type-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/code.js" /* webpackChunkName: "component---src-pages-components-type-code-js" */),
  "component---src-pages-components-type-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/design.js" /* webpackChunkName: "component---src-pages-components-type-design-js" */),
  "component---src-pages-components-type-headline-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/headline/code.js" /* webpackChunkName: "component---src-pages-components-type-headline-code-js" */),
  "component---src-pages-components-type-headline-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/headline/design.js" /* webpackChunkName: "component---src-pages-components-type-headline-design-js" */),
  "component---src-pages-components-type-item-title-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/item-title/code.js" /* webpackChunkName: "component---src-pages-components-type-item-title-code-js" */),
  "component---src-pages-components-type-item-title-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/item-title/design.js" /* webpackChunkName: "component---src-pages-components-type-item-title-design-js" */),
  "component---src-pages-components-type-subhead-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/subhead/code.js" /* webpackChunkName: "component---src-pages-components-type-subhead-code-js" */),
  "component---src-pages-components-type-subhead-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/subhead/design.js" /* webpackChunkName: "component---src-pages-components-type-subhead-design-js" */),
  "component---src-pages-components-type-text-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/text/code.js" /* webpackChunkName: "component---src-pages-components-type-text-code-js" */),
  "component---src-pages-components-type-text-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/components/type/text/design.js" /* webpackChunkName: "component---src-pages-components-type-text-design-js" */),
  "component---src-pages-get-started-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/get-started/code.js" /* webpackChunkName: "component---src-pages-get-started-code-js" */),
  "component---src-pages-get-started-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/get-started/design.js" /* webpackChunkName: "component---src-pages-get-started-design-js" */),
  "component---src-pages-get-started-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/get-started/index.js" /* webpackChunkName: "component---src-pages-get-started-index-js" */),
  "component---src-pages-guidelines-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/code.js" /* webpackChunkName: "component---src-pages-guidelines-code-js" */),
  "component---src-pages-guidelines-colors-brand-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/brand/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-brand-code-js" */),
  "component---src-pages-guidelines-colors-brand-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/brand/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-brand-design-js" */),
  "component---src-pages-guidelines-colors-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-code-js" */),
  "component---src-pages-guidelines-colors-content-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/content/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-content-code-js" */),
  "component---src-pages-guidelines-colors-content-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/content/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-content-design-js" */),
  "component---src-pages-guidelines-colors-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-design-js" */),
  "component---src-pages-guidelines-colors-identity-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/identity/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-identity-code-js" */),
  "component---src-pages-guidelines-colors-identity-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/identity/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-identity-design-js" */),
  "component---src-pages-guidelines-colors-tagging-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/tagging/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-tagging-code-js" */),
  "component---src-pages-guidelines-colors-tagging-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/tagging/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-tagging-design-js" */),
  "component---src-pages-guidelines-colors-themes-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/themes/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-themes-code-js" */),
  "component---src-pages-guidelines-colors-themes-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/themes/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-themes-design-js" */),
  "component---src-pages-guidelines-colors-utility-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/utility/code.js" /* webpackChunkName: "component---src-pages-guidelines-colors-utility-code-js" */),
  "component---src-pages-guidelines-colors-utility-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/colors/utility/design.js" /* webpackChunkName: "component---src-pages-guidelines-colors-utility-design-js" */),
  "component---src-pages-guidelines-content-selection-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/content-selection/code.js" /* webpackChunkName: "component---src-pages-guidelines-content-selection-code-js" */),
  "component---src-pages-guidelines-content-selection-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/content-selection/design.js" /* webpackChunkName: "component---src-pages-guidelines-content-selection-design-js" */),
  "component---src-pages-guidelines-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/design.js" /* webpackChunkName: "component---src-pages-guidelines-design-js" */),
  "component---src-pages-guidelines-iconography-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/iconography/code.js" /* webpackChunkName: "component---src-pages-guidelines-iconography-code-js" */),
  "component---src-pages-guidelines-iconography-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/iconography/design.js" /* webpackChunkName: "component---src-pages-guidelines-iconography-design-js" */),
  "component---src-pages-guidelines-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/index.js" /* webpackChunkName: "component---src-pages-guidelines-index-js" */),
  "component---src-pages-guidelines-layering-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/code.js" /* webpackChunkName: "component---src-pages-guidelines-layering-code-js" */),
  "component---src-pages-guidelines-layering-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/design.js" /* webpackChunkName: "component---src-pages-guidelines-layering-design-js" */),
  "component---src-pages-guidelines-layering-division-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/division/code.js" /* webpackChunkName: "component---src-pages-guidelines-layering-division-code-js" */),
  "component---src-pages-guidelines-layering-division-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/division/design.js" /* webpackChunkName: "component---src-pages-guidelines-layering-division-design-js" */),
  "component---src-pages-guidelines-layering-elevation-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/elevation/code.js" /* webpackChunkName: "component---src-pages-guidelines-layering-elevation-code-js" */),
  "component---src-pages-guidelines-layering-elevation-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/elevation/design.js" /* webpackChunkName: "component---src-pages-guidelines-layering-elevation-design-js" */),
  "component---src-pages-guidelines-layering-shadows-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/shadows/code.js" /* webpackChunkName: "component---src-pages-guidelines-layering-shadows-code-js" */),
  "component---src-pages-guidelines-layering-shadows-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/layering/shadows/design.js" /* webpackChunkName: "component---src-pages-guidelines-layering-shadows-design-js" */),
  "component---src-pages-guidelines-space-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/space/code.js" /* webpackChunkName: "component---src-pages-guidelines-space-code-js" */),
  "component---src-pages-guidelines-space-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/space/design.js" /* webpackChunkName: "component---src-pages-guidelines-space-design-js" */),
  "component---src-pages-guidelines-typography-code-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/typography/code.js" /* webpackChunkName: "component---src-pages-guidelines-typography-code-js" */),
  "component---src-pages-guidelines-typography-design-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/guidelines/typography/design.js" /* webpackChunkName: "component---src-pages-guidelines-typography-design-js" */),
  "component---src-pages-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-patterns-empty-states-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/patterns/empty-states/index.js" /* webpackChunkName: "component---src-pages-patterns-empty-states-index-js" */),
  "component---src-pages-patterns-forms-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/patterns/forms/index.js" /* webpackChunkName: "component---src-pages-patterns-forms-index-js" */),
  "component---src-pages-patterns-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/patterns/index.js" /* webpackChunkName: "component---src-pages-patterns-index-js" */),
  "component---src-pages-patterns-mobile-navigation-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/patterns/mobile-navigation/index.js" /* webpackChunkName: "component---src-pages-patterns-mobile-navigation-index-js" */),
  "component---src-pages-patterns-tabs-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/patterns/tabs/index.js" /* webpackChunkName: "component---src-pages-patterns-tabs-index-js" */),
  "component---src-pages-patterns-user-lists-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/patterns/user-lists/index.js" /* webpackChunkName: "component---src-pages-patterns-user-lists-index-js" */),
  "component---src-pages-release-notes-2018-03-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/03/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-03-index-js" */),
  "component---src-pages-release-notes-2018-04-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/04/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-04-index-js" */),
  "component---src-pages-release-notes-2018-05-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/05/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-05-index-js" */),
  "component---src-pages-release-notes-2018-06-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/06/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-06-index-js" */),
  "component---src-pages-release-notes-2018-07-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/07/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-07-index-js" */),
  "component---src-pages-release-notes-2018-08-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/08/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-08-index-js" */),
  "component---src-pages-release-notes-2018-09-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/09/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-09-index-js" */),
  "component---src-pages-release-notes-2018-10-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/10/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-10-index-js" */),
  "component---src-pages-release-notes-2018-11-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/11/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-11-index-js" */),
  "component---src-pages-release-notes-2018-12-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2018/12/index.js" /* webpackChunkName: "component---src-pages-release-notes-2018-12-index-js" */),
  "component---src-pages-release-notes-2019-01-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/01/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-01-index-js" */),
  "component---src-pages-release-notes-2019-02-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/02/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-02-index-js" */),
  "component---src-pages-release-notes-2019-03-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/03/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-03-index-js" */),
  "component---src-pages-release-notes-2019-04-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/04/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-04-index-js" */),
  "component---src-pages-release-notes-2019-05-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/05/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-05-index-js" */),
  "component---src-pages-release-notes-2019-06-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/06/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-06-index-js" */),
  "component---src-pages-release-notes-2019-07-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/07/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-07-index-js" */),
  "component---src-pages-release-notes-2019-08-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/08/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-08-index-js" */),
  "component---src-pages-release-notes-2019-09-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/09/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-09-index-js" */),
  "component---src-pages-release-notes-2019-10-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/10/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-10-index-js" */),
  "component---src-pages-release-notes-2019-11-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/11/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-11-index-js" */),
  "component---src-pages-release-notes-2019-12-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2019/12/index.js" /* webpackChunkName: "component---src-pages-release-notes-2019-12-index-js" */),
  "component---src-pages-release-notes-2020-01-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2020/01/index.js" /* webpackChunkName: "component---src-pages-release-notes-2020-01-index-js" */),
  "component---src-pages-release-notes-2020-02-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/2020/02/index.js" /* webpackChunkName: "component---src-pages-release-notes-2020-02-index-js" */),
  "component---src-pages-release-notes-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/release-notes/index.js" /* webpackChunkName: "component---src-pages-release-notes-index-js" */),
  "component---src-pages-resources-code-helpers-buttons-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-helpers/buttons/index.js" /* webpackChunkName: "component---src-pages-resources-code-helpers-buttons-index-js" */),
  "component---src-pages-resources-code-helpers-general-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-helpers/general/index.js" /* webpackChunkName: "component---src-pages-resources-code-helpers-general-index-js" */),
  "component---src-pages-resources-code-helpers-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-helpers/index.js" /* webpackChunkName: "component---src-pages-resources-code-helpers-index-js" */),
  "component---src-pages-resources-code-helpers-space-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-helpers/space/index.js" /* webpackChunkName: "component---src-pages-resources-code-helpers-space-index-js" */),
  "component---src-pages-resources-code-helpers-theme-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-helpers/theme/index.js" /* webpackChunkName: "component---src-pages-resources-code-helpers-theme-index-js" */),
  "component---src-pages-resources-code-helpers-type-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-helpers/type/index.js" /* webpackChunkName: "component---src-pages-resources-code-helpers-type-index-js" */),
  "component---src-pages-resources-code-variables-colors-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/colors/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-colors-index-js" */),
  "component---src-pages-resources-code-variables-deprecated-variables-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/deprecated-variables/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-deprecated-variables-index-js" */),
  "component---src-pages-resources-code-variables-icons-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/icons/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-icons-index-js" */),
  "component---src-pages-resources-code-variables-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-index-js" */),
  "component---src-pages-resources-code-variables-removed-variables-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/removed-variables/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-removed-variables-index-js" */),
  "component---src-pages-resources-code-variables-shadows-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/shadows/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-shadows-index-js" */),
  "component---src-pages-resources-code-variables-space-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/space/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-space-index-js" */),
  "component---src-pages-resources-code-variables-zindex-elevation-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/code-variables/zindex-elevation/index.js" /* webpackChunkName: "component---src-pages-resources-code-variables-zindex-elevation-index-js" */),
  "component---src-pages-resources-design-assets-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/design-assets/index.js" /* webpackChunkName: "component---src-pages-resources-design-assets-index-js" */),
  "component---src-pages-resources-design-assets-typefaces-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/design-assets/typefaces/index.js" /* webpackChunkName: "component---src-pages-resources-design-assets-typefaces-index-js" */),
  "component---src-pages-resources-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/resources/index.js" /* webpackChunkName: "component---src-pages-resources-index-js" */),
  "component---src-pages-words-content-elements-action-words-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/action-words/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-action-words-index-js" */),
  "component---src-pages-words-content-elements-calls-to-action-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/calls-to-action/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-calls-to-action-index-js" */),
  "component---src-pages-words-content-elements-date-time-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/date-time/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-date-time-index-js" */),
  "component---src-pages-words-content-elements-event-details-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/event-details/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-event-details-index-js" */),
  "component---src-pages-words-content-elements-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-index-js" */),
  "component---src-pages-words-content-elements-labels-non-label-text-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/labels-non-label-text/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-labels-non-label-text-index-js" */),
  "component---src-pages-words-content-elements-team-details-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/content-elements/team-details/index.js" /* webpackChunkName: "component---src-pages-words-content-elements-team-details-index-js" */),
  "component---src-pages-words-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/index.js" /* webpackChunkName: "component---src-pages-words-index-js" */),
  "component---src-pages-words-writing-guidelines-headings-lists-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/headings-lists/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-headings-lists-index-js" */),
  "component---src-pages-words-writing-guidelines-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-index-js" */),
  "component---src-pages-words-writing-guidelines-mobile-notifications-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/mobile-notifications/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-mobile-notifications-index-js" */),
  "component---src-pages-words-writing-guidelines-punctuation-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/punctuation/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-punctuation-index-js" */),
  "component---src-pages-words-writing-guidelines-transactional-emails-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/transactional-emails/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-transactional-emails-index-js" */),
  "component---src-pages-words-writing-guidelines-voice-tone-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/voice-tone/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-voice-tone-index-js" */),
  "component---src-pages-words-writing-guidelines-your-my-neutral-index-js": () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/src/pages/words/writing-guidelines/your-my-neutral/index.js" /* webpackChunkName: "component---src-pages-words-writing-guidelines-your-my-neutral-index-js" */)
}

exports.data = () => import("/Users/derek.watson/Documents/dev/uniform/uniform.hudl.com/.cache/data.json")

